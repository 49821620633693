window.realjQuery = jQuery;

jQuery(function ($) {
    $('#commentboxopenbutton').click(function (event) {
        event.preventDefault();
        $('.commentbox').slideToggle();
        $('#commentboxopenbutton').hide();
        $('#commentboxclosebutton').show();
    });

    $('#commentboxclosebutton').click(function (event) {
        event.preventDefault();
        $('.commentbox').slideToggle();
        $('#commentboxopenbutton').show();
        $('#commentboxclosebutton').hide();
    });

    $('.fullpage table').addClass("table");

    //SLICK
    setTimeout(function(){
        $('.slick').slick({
            arrows: false,
            dots: true,
            slide: 'li',
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 300
        });
    },10);

    //SLICK
    setTimeout(function(){
        $('.slick3er').slick({
            arrows: true,
            speed: 300,
            slide: 'li',
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    },10);

    // BARRIEREFREIE TOOLBAR
    $('.toggle-grayscale').on('click', function(){
        if ((sessionStorage.getItem('fGrayscale') === "1") && ($('body').hasClass("grayscalestyle"))) {
            $('body').removeClass("grayscalestyle");
            sessionStorage.setItem('fGrayscale', "0");
        }else{
            $('body').addClass('grayscalestyle');
            sessionStorage.setItem('fGrayscale', "1");
        }
    });

    $('.toggle-contrast').on('click', function(){
        if ((sessionStorage.getItem('fContrast') === "1") && ($('body').hasClass("contraststyle"))) {
            $('body').removeClass("contraststyle");
            sessionStorage.setItem('fContrast', "0");
        }else{
            $('body').addClass('contraststyle');
            sessionStorage.setItem('fContrast', "1");
        }
    });

    $('.up-fontsize').on('click', function(){
        var actualfontsize = sessionStorage.getItem('fFontsize');
        if(sessionStorage.getItem('fFontsize') === null) {actualfontsize = "100";}

        if (actualfontsize < "150"){
            actualfontsize = parseInt(actualfontsize) + 10;
            $('html').css('font-size', actualfontsize+'%');
            sessionStorage.setItem('fFontsize', actualfontsize);
        }
    });
    $('.down-fontsize').on('click', function(){
        var actualfontsize = sessionStorage.getItem('fFontsize');
        if(sessionStorage.getItem('fFontsize') === null) {actualfontsize = "100";}

        if (actualfontsize > "100"){
            actualfontsize = parseInt(actualfontsize) - 10;
            $('html').css('font-size', actualfontsize+'%');
            sessionStorage.setItem('fFontsize', actualfontsize);
        }
    });

    //ONLOAD CHECK
    var actualfontsize = sessionStorage.getItem('fFontsize') || 100;
    $('html').css('font-size', actualfontsize+'%');


    if (sessionStorage.getItem('fGrayscale') === "1") {
        $('body').addClass('grayscalestyle');
    }

    if (sessionStorage.getItem('fContrast') === "1") {
        $('body').addClass('contraststyle');
    }


});